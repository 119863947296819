import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c9adbe9e = () => interopDefault(import('..\\pages\\about-will.vue' /* webpackChunkName: "pages_about-will" */))
const _0fedd311 = () => interopDefault(import('..\\pages\\contact.vue' /* webpackChunkName: "pages_contact" */))
const _1d3ddfa0 = () => interopDefault(import('..\\pages\\deerstalking.vue' /* webpackChunkName: "pages_deerstalking" */))
const _e53b857a = () => interopDefault(import('..\\pages\\gallery.vue' /* webpackChunkName: "pages_gallery" */))
const _05a17f18 = () => interopDefault(import('..\\pages\\important-info.vue' /* webpackChunkName: "pages_important-info" */))
const _356c47cc = () => interopDefault(import('..\\pages\\packages.vue' /* webpackChunkName: "pages_packages" */))
const _53951dfa = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-will",
    component: _c9adbe9e,
    name: "about-will"
  }, {
    path: "/contact",
    component: _0fedd311,
    name: "contact"
  }, {
    path: "/deerstalking",
    component: _1d3ddfa0,
    name: "deerstalking"
  }, {
    path: "/gallery",
    component: _e53b857a,
    name: "gallery"
  }, {
    path: "/important-info",
    component: _05a17f18,
    name: "important-info"
  }, {
    path: "/packages",
    component: _356c47cc,
    name: "packages"
  }, {
    path: "/",
    component: _53951dfa,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
